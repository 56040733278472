export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";

export const LOAD_ADMIN_REQUEST = "LOAD_ADMIN_REQUEST";
export const LOAD_ADMIN_SUCCESS = "LOAD_ADMIN_SUCCESS";
export const LOAD_ADMIN_FAIL = "LOAD_ADMIN_FAIL";

export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_ADMIN_SUCCESS";
export const LOGOUT_ADMIN_FAIL = "LOGOUT_ADMIN_FAIL";

export const ADMIN_UPDATE_REQUEST = "ADMIN_UPDATE_REQUEST";
export const ADMIN_UPDATE_SUCCESS = "ADMIN_UPDATE_SUCCESS";
export const ADMIN_UPDATE_RESET = "ADMIN_UPDATE_RESET";
export const ADMIN_UPDATE_FAIL = "ADMIN_UPDATE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";