export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAIL = "GOOGLE_LOGIN_FAIL";

export const FACEBOOK_LOGIN_REQUEST = "FACEBOOK_LOGIN_REQUEST";
export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";
export const FACEBOOK_LOGIN_FAIL = "FACEBOOK_LOGIN_FAIL";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";


export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";


export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";

export const USER_PASSWORD_UPDATE_REQUEST = "USER_PASSWORD_UPDATE_REQUEST";
export const USER_PASSWORD_UPDATE_SUCCESS = "USER_PASSWORD_UPDATE_SUCCESS";
export const USER_PASSWORD_UPDATE_RESET = "USER_PASSWORD_UPDATE_RESET";
export const USER_PASSWORD_UPDATE_FAIL = "USER_PASSWORD_UPDATE_FAIL";

export const FORGET_PASSWORD_UPDATE_REQUEST = "FORGET_PASSWORD_UPDATE_REQUEST";
export const FORGET_PASSWORD_UPDATE_SUCCESS = "FORGET_PASSWORD_UPDATE_SUCCESS";
export const FORGET_PASSWORD_UPDATE_RESET = "FORGET_PASSWORD_UPDATE_RESET";
export const FORGET_PASSWORD_UPDATE_FAIL = "FORGET_PASSWORD_UPDATE_FAIL";

export const RESET_PASSWORD_UPDATE_REQUEST = "RESET_PASSWORD_UPDATE_REQUEST";
export const RESET_PASSWORD_UPDATE_SUCCESS = "RESET_PASSWORD_UPDATE_SUCCESS";
export const RESET_PASSWORD_UPDATE_RESET = "RESET_PASSWORD_UPDATE_RESET";
export const RESET_PASSWORD_UPDATE_FAIL = "RESET_PASSWORD_UPDATE_FAIL";




export const CLEAR_ERRORS = "CLEAR_ERRORS";