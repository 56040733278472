
export const ADD_MAINTRAVELER_REQUEST = "ADD_MAINTRAVELER_REQUEST";
export const ADD_MAINTRAVELER_SUCCESS = "ADD_MAINTRAVELER_SUCCESS";
export const ADD_MAINTRAVELER_RESET = "ADD_MAINTRAVELER_RESET";
export const ADD_MAINTRAVELER_FAIL = "ADD_MAINTRAVELER_FAIL";

export const ADD_COTRAVELER_REQUEST = "ADD_COTRAVELER_REQUEST";
export const ADD_COTRAVELER_SUCCESS = "ADD_COTRAVELER_SUCCESS";
export const ADD_COTRAVELER_RESET = "ADD_COTRAVELER_RESET";
export const ADD_COTRAVELER_FAIL = "ADD_COTRAVELER_FAIL";

export const TRAVELER_DETAILS_REQUEST = "TRAVELER_DETAILS_REQUEST";
export const TRAVELER_DETAILS_SUCCESS = "TRAVELER_DETAILS_SUCCESS";
export const TRAVELER_DETAILS_FAIL = "TRAVELER_DETAILS_FAIL";

export const ALL_TRAVELERS_REQUEST = "ALL_TRAVELERS_REQUEST";
export const ALL_TRAVELERS_SUCCESS = "ALL_TRAVELERS_SUCCESS";
export const ALL_TRAVELERS_FAIL = "ALL_TRAVELERS_FAIL";

export const GET_BY_USER_REQUEST = "GET_BY_USER_REQUEST";
export const GET_BY_USER_SUCCESS = "GET_BY_USER_SUCCESS";
export const GET_BY_USER_FAIL = "GET_BY_USER_FAIL";

export const SEARCH_TRAVELERS_REQUEST = "SEARCH_TRAVELERS_REQUEST";
export const SEARCH_TRAVELERS_SUCCESS = "SEARCH_TRAVELERS_SUCCESS";
export const SEARCH_TRAVELERS_FAIL = "SEARCH_TRAVELERS_FAIL";

export const FILTERBYVISATYPE_TRAVELERS_REQUEST = "FILTERBYVISATYPE_TRAVELERS_REQUEST";
export const FILTERBYVISATYPE_TRAVELERS_SUCCESS = "FILTERBYVISATYPE_TRAVELERS_SUCCESS";
export const FILTERBYVISATYPE_TRAVELERS_FAIL = "FILTERBYVISATYPE_TRAVELERS_FAIL";

export const FILTERBYDATE_TRAVELERS_REQUEST = "FILTERBYDATE_TRAVELERS_REQUEST";
export const FILTERBYDATE_TRAVELERS_SUCCESS = "FILTERBYDATE_TRAVELERS_SUCCESS";
export const FILTERBYDATE_TRAVELERS_FAIL = "FILTERBYDATE_TRAVELERS_FAIL";

export const ADD_OTHER_FIELDS_REQUEST = "ADD_OTHER_FIELDS_REQUEST";
export const ADD_OTHER_FIELDS_SUCCESS = "ADD_OTHER_FIELDS_SUCCESS";
export const ADD_OTHER_FIELDS_RESET = "ADD_OTHER_FIELDS_RESET";
export const ADD_OTHER_FIELDS_FAIL = "ADD_OTHER_FIELDS_FAIL";

export const CHANGE_COTRAVELERS_STATUS_REQUEST = "CHANGE_COTRAVELERS_STATUS_REQUEST";
export const CHANGE_COTRAVELERS_STATUS_SUCCESS = "CHANGE_COTRAVELERS_STATUS_SUCCESS";
export const CHANGE_COTRAVELERS_STATUS_RESET = "CHANGE_COTRAVELERS_STATUS_RESET";
export const CHANGE_COTRAVELERS_STATUS_FAIL = "CHANGE_COTRAVELERS_STATUS_FAIL";


export const DELETE_TRAVELER_REQUEST = "DELETE_TRAVELER_REQUEST";
export const DELETE_TRAVELER_SUCCESS = "DELETE_TRAVELER_SUCCESS";
export const DELETE_TRAVELER_RESET = "DELETE_TRAVELER_RESET";
export const DELETE_TRAVELER_FAIL = "DELETE_TRAVELER_FAIL";

export const TRACK_TRAVELER_REQUEST = "TRACK_TRAVELER_REQUEST";
export const TRACK_TRAVELER_SUCCESS = "TRACK_TRAVELER_SUCCESS";
export const TRACK_TRAVELER_RESET = "TRACK_TRAVELER_RESET";
export const TRACK_TRAVELER_FAIL = "TRACK_TRAVELER_FAIL";


export const CLEAR_ERRORS = "CLEAR_ERRORS";