export const ALL_VISA_REQUEST = "ALL_VISA_REQUEST";
export const ALL_VISA_SUCCESS = "ALL_VISA_SUCCESS";
export const ALL_VISA_FAIL = "ALL_VISA_FAIL";

export const VISA_DETAILS_REQUEST = "VISA_DETAILS_REQUEST";
export const VISA_DETAILS_SUCCESS = "VISA_DETAILS_SUCCESS";
export const VISA_DETAILS_FAIL = "VISA_DETAILS_FAIL";

export const VISA_UPDATE_REQUEST = "VISA_UPDATE_REQUEST";
export const VISA_UPDATE_SUCCESS = "VISA_UPDATE_SUCCESS";
export const VISA_UPDATE_RESET = "VISA_UPDATE_RESET";
export const VISA_UPDATE_FAIL = "VISA_UPDATE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";