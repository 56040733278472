import React from "react";

function AdminDashbord() {
  return (
    <>
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-lg-10 ms-auto p-4 overflow-hidden text-center"
          id="main-content"
        >
          gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrr gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn gsutrfsdjdcn d lorem25
          ssduiwehd8ewxbgy7ercgbrygfbxerygzuregxfyxegunuzgrygehb erhrbheb vhzfbvhye gybhue bhbhufe bhuefbmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasssssssssssssssssssssssssbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbeeeeeeeeeeeeeeeeeeeeeeeeeeeeeejjjjjjjjjjjjjjjjjjjjjjjrrrrrrrrrrrrrrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdnrrrrrrrkkkkkkkkkkkkkkkddddddddddddddmmmmmmmmmaaaaaaaaaaaaaaannsjdn
        </div>
      </div>
    </div>
    </>
  );
}

export default AdminDashbord;
